export const VENEER_SECONDARY_APPEARANCE = 'secondary'
export const VENEER_TERTIARY_APPEARANCE = 'ghost'
export const VENEER_WHITE = 'colorWhite'
export const VENEER_HP_BLUE_6 = 'colorHpBlue6'
export const ACCOUNT_TYPE_COMPANY = 'BusinessTransactionalSMB'
export const ACCOUNT_TYPE_PERSONAL = 'Personal'
export const CARD_TYPE_BUSINESS = 'Business'
export const CONNECTION_ETHERNET = 'ethernet'
export const CONNECTION_WIRELESS = 'wireless'
export const UNSUPPORTED_BROWSER = 'unsupported_browser'
export const EMPTY_ARROW_FUNCTION = () => null
export const YETI_PROGRAM_ID = 'YETI'

export const UserOs = {
  ios: 'ios',
  android: 'android',
  linux: 'linux',
  mac: 'mac',
  windows: 'windows',
  chrome: 'chrome',
  unknown: 'unknown'
}

export const ConnectionTypes = {
  skip_internet: 'skip-internet',
  usb: 'usb'
}

// Keeping these in constants as it allows the hook to be mocked more simply
export const USE_CONTENTSTACK_QUERY_TYPES = {
  DERIVATIVE_NAME: 'derivative_name',
  INSTRUCTION: 'instruction',
  CHECKLIST: 'checklist',
  SUPPORTED_PRODUCTS: 'supported_products',
  OPERATING_SYSTEM: 'operating_system'
}

export const ContentStackStacks = {
  pie: {
    api_key: 'bltbd278b8fc62d8a81',
    delivery_token: 'csf87a38deb22ccca3b00c5e6e',
    env_name: 'pie',
    enable_live_preview: true,
    management_token: 'csf6642729128c250b98bcf981'
  },
  stage: {
    api_key: 'bltbd278b8fc62d8a81',
    delivery_token: 'csc00de34ed25342b79c8c67ee',
    env_name: 'stage'
  },
  prod: {
    api_key: 'bltbd278b8fc62d8a81',
    delivery_token: 'cs5dd29cc573471aaa1cf10a57',
    env_name: 'prod'
  }
}

export const UnsupportedCmsIds = {
  [UserOs.chrome]: 'unsupported_os_chromebook',
  [UserOs.windows]: 'unsupported_os_general',
  [UserOs.linux]: 'unsupported_os_general_browser',
  [UserOs.mac]: 'unsupported_os_macos',
  unsupported_browser: 'unsupported_os_general_browser'
}

export const ContentStackTypes = {
  printer_specific_landing_page: {
    content_type_uid: 'printer_specific_landing_page'
  },
  tde_layout: {
    content_type_uid: 'tde_layout',
    entry_uid: 'bltbee092510ffe4cac'
  },
  language_selector: {
    entry_uid: 'blt1ab3cfbc5e7e76af',
    content_type_uid: 'language_selector'
  },
  page_not_found: {
    entry_uid: 'bltcdd5d2848943188a',
    content_type_uid: 'page_not_found'
  },
  carousel_instruction: {
    content_type_uid: 'instruction_carousel'
  },
  delta_access_page: {
    entry_uid: 'blt28d0b7352883933f',
    content_type_uid: 'delta_access_page'
  },
  connect_printer_to_network: {
    content_type_uid: 'connect_printer_to_network'
  },
  printer_use_choice: {
    entry_uid: 'blt7c78ca69c1425da8',
    content_type_uid: 'printer_use_choice'
  },
  setup_checklist: {
    content_type_uid: 'setup_checklist'
  },
  subtab_layout: {
    content_type_uid: 'subtab_layout'
  },
  finish_setup_business: {
    entry_uid: 'blte03f4891540bafbe',
    content_type_uid: 'generic_card'
  },
  wifi_setup_modal: {
    content_type_uid: 'modal'
  },
  use_offline_modal_desktop: {
    entry_uid: 'blta631a580608b679e',
    content_type_uid: 'modal'
  },
  use_offline_modal_mobile: {
    entry_uid: 'bltd1c5d636c3cb5c35',
    content_type_uid: 'modal'
  },
  connect_usb: {
    content_type_uid: 'generic_card'
  },
  [UnsupportedCmsIds[UserOs.chrome]]: {
    entry_uid: 'blt403522a48068a1cc',
    content_type_uid: 'unsupported_os'
  },
  unsupported_install_qrcode: {
    entry_uid: 'bltb1e8a72253e869bc',
    content_type_uid: 'unsupported_os'
  },
  [UnsupportedCmsIds[UserOs.windows]]: {
    entry_uid: 'blt1157be8346ec311e',
    content_type_uid: 'unsupported_os'
  },
  [UnsupportedCmsIds[UserOs.mac]]: {
    entry_uid: 'blt166409b88c399537',
    content_type_uid: 'unsupported_os'
  },
  [UnsupportedCmsIds[UserOs.linux]]: {
    entry_uid: 'blt945c6f58cc088be8',
    content_type_uid: 'unsupported_os'
  },
  [UnsupportedCmsIds.unsupported_browser]: {
    entry_uid: 'blt547ccc8abb4cf086',
    content_type_uid: 'unsupported_os'
  },
  driver_download: {
    entry_uid: 'bltcdae44c026c955cd',
    content_type_uid: 'generic_card'
  },
  driver_download_intranet: {
    entry_uid: 'bltfe7c5b6bb3fcbc94',
    content_type_uid: 'generic_card'
  },
  driver_not_found: {
    entry_uid: 'blt2acd3c2edc90da02',
    content_type_uid: 'generic_card'
  },
  select_usb_on_display: {
    content_type_uid: 'generic_card'
  },
  hp_software: {
    entry_uid: 'bltc94810e34efc22c7',
    content_type_uid: 'hp_software'
  },
  hp_software_mobile: {
    entry_uid: 'blt42cd4e1a862e7387',
    content_type_uid: 'hp_software'
  },
  external_links: {
    entry_uid: 'bltc022dbc8cc109a41',
    content_type_uid: 'external_links'
  },
  trouble_installing_modal_desktop_win: {
    entry_uid: 'blte52cca27a8c8ba63',
    content_type_uid: 'modal'
  },
  trouble_installing_modal_desktop_mac: {
    entry_uid: 'blt3ac63b0954400607',
    content_type_uid: 'modal'
  },
  trouble_installing_modal_mobile: {
    entry_uid: 'bltf992f5c1c77da8d9',
    content_type_uid: 'modal'
  },
  skip_internet_connection: {
    entry_uid: 'blt8a0443601036eb3c',
    content_type_uid: 'generic_card'
  },
  start_airprint: {
    entry_uid: 'blt7b5b730b6a85a0bf',
    content_type_uid: 'generic_card'
  },
  start_printing_mobile: {
    entry_uid: 'blt046ed8a63b4cce15',
    content_type_uid: 'start_printing_mobile'
  },
  tab_definition: {
    content_type_uid: 'tab_definition'
  },
  hp_support: {
    entry_uid: 'blt9df9361083da4e77',
    content_type_uid: 'hp_support'
  },
  learn_printer_features: {
    entry_uid: 'blt93990b2682f317fb',
    content_type_uid: 'subtab_layout'
  },
  switch_to_mobile: {
    content_type_uid: 'modal'
  },
  configuration_definitions: {
    content_type_uid: 'configuration_definitions'
  }
}

export const ContentStackGlobalFields = {
  derivative_names: 'derivative_names'
}

export const Instructions = {
  install_ink: 'install-ink',
  load_paper: 'load-paper',
  alignment: 'alignment',
  power_on: 'power-on'
}
export const Paths = {
  home: '/:sku',
  country_language: '/country-language',
  load_paper: '/load-paper',
  install_ink: '/install-ink',
  alignment: '/alignment',
  connect_printer_to_network: '/printer-network',
  set_up_checklist: '/setup-checklist',
  printer_use_choice: '/printer-use',
  setup_checklist: '/setup-checklist',
  connect_usb: '/connect-usb',
  unsupported_os: '/unsupported-os',
  driver_download: '/driver-download',
  driver_after: '/driver-after',
  select_usb_on_display: '/usb-printer-display',
  not_found: '/not-found',
  hp_software: '/hp-software',
  finish_setup_business: '/finish-setup-business',
  skip_internet_connection: '/skip-internet-connection',
  start_airprint: '/start-airprint',
  start_printing_mobile: '/start-printing-mobile',
  install: '/install',
  install_unsupported_os: '/install-unsupported-os'
}
export const Urls = {
  hp123: 'https://123.hp.com/',
  hpPlus: 'https://hp.com/plus/',
  instantInk: 'https://instantink.com/',
  swls: (sku) =>
    `https://h20614.www2.hp.com/ediags/solutions/software/v3?client=PSW&os=Windows+NT+10.0&ProductNumber=${sku}`
}

export const LocalStoreKeys = {
  user: 'user',
  selectedAccordionItems: 'selectedAccordionItems'
}

export const DriverTypes = {
  win_sw_setup_integrated: 'WINSWSETUPINTEGRATED',
  basic_x64: 'BASICX64',
  basic_x64_uss: 'USS_BASICX64',
  basic_x86: 'BASICX86',
  basic_x86_uss: 'USS_BASICX86'
}

export const GenericCardTypes = {
  connect_usb: 'CONNECT_USB',
  driver_not_found: 'DRIVER_NOT_FOUND',
  finish_setup: 'FINISH_SETUP',
  install_driver: 'INSTALL_DRIVER',
  install_driver_intranet: 'INSTALL_DRIVER_INTRANET',
  select_usb_display: 'SELECT_USB_DISPLAY',
  skip_internet_connection: 'SKIP_INTERNET_CONNECTION',
  start_airprint: 'START_AIRPRINT'
}

export const ModalTypes = {
  use_offline: 'USE_OFFLINE',
  wifi_setup: 'WIFI_SETUP',
  trouble_installing: 'TROUBLE_INSTALLING',
  switch_to_mobile: 'SWITCH_TO_MOBILE'
}

export const FeatureFlagKeys = {
  navigation: 'olex-release-traffic-director-navigation',
  betaAccessCode: 'olex-release-traffic-director-beta-access-code',
  saltString: 'olex-traffic-director-salt',
  landingPagePoC: 'olex-release-traffic-director-landing-page-poc'
}

export const ConfigurationDefinitionsKeys = {
  compatibilityDefinitions: 'compatibility',
  skuWithoutUsbDefinition: 'sku_without_usb'
}

export const Errors = {
  cms_page_error: 'cms_page_error'
}
